import { createRouter, createWebHistory } from "@ionic/vue-router";
import TabsPage from "../views/TabsPage.vue";

const routes = [
  {
    path: "/",
    redirect: "/main/home",
  },
  {
    path: "/main/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/main/home",
      },
      {
        path: "home",
        component: () => import("@/views/Tab1Page.vue"),
      },
      {
        path: "my-lesson",
        component: () => import("@/views/Tab2Page.vue"),
      },
      {
        path: "notification",
        component: () => import("@/views/Tab3Page.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/Tab4Page.vue"),
      },

    ],
  },
  {
    path: "/nyantri-kilat/",
    component: () => import("@/views/nyantriKilat/mainView.vue"),
    children: [
    ],

  },
  {
    path: "/auth/",
    component: () => import("@/views/auth/MainView.vue"),
    children: [
      { path: "", redirect: "/auth/login" },
      { path: "login", component: () => import("@/views/auth/LoginPage.vue") },
      { path: "register", component: () => import("@/views/auth/RegisterPage.vue") },
      { path: "forgot-password", component: () => import("@/views/auth/ForgotPasswordPage.vue") },
    ],
  },
  {
    path: "/profile/",
    component: () => import("@/views/profile/MainView.vue"),
    children: [
      { path: "", redirect: "/profile/edit" },
      { path: "edit", component: () => import("@/views/profile/EditPage.vue") },
    ],
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
