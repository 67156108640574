import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: {}
    }
  },
  actions: {
    setUser(payload) {
      this.user = payload;
    }
  },
  getters: {
    getUser: state => state.user
  }
});