<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="tab1" href="/main/home">
          <ion-icon :icon="homeOutline" />
          <ion-label>Beranda</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/main/my-lesson">
          <ion-icon :icon="bookOutline" />
          <ion-label>Ngajiku</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3" href="/main/notification">
          <ion-icon :icon="notificationsOutline" />
          <ion-label>Notifikasi</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4" href="/main/profile">
          <ion-icon :icon="personOutline" />
          <ion-label>Profil</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  homeOutline,
  bookOutline,
  notificationsOutline,
  personOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "TabsPage",
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
  },
  setup() {
    return {
      homeOutline,
      bookOutline,
      notificationsOutline,
      personOutline,
    };
  },
});
</script>
