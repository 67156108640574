<template>
  <ion-app class="max-width-screen">
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import "./App.css";

import fbsAuth from "./modules/fbsAuth";
import { onAuthStateChanged } from "firebase/auth";

import { useUserStore } from "./stores/userStore";

onAuthStateChanged(fbsAuth, (user) => {
  const userStore = useUserStore();
  if (user) {
    userStore.setUser(user);
  } else {
    userStore.setUser({});
  }
});
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
});
</script>

<style scoped>
.max-width-screen {
  max-width: 1024px;
  margin: 0px auto;
}
</style>
